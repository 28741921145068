export const UFS = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const PRICE_OPTIONS_SAFE_YEARLY = [
  {
    Title: "Ouro",
    id: 1,
    type: "ano",
    value: "10.000,00",
    price: 62.59,
  },
  {
    Title: "Prata",
    id: 2,
    type: "ano",
    value: "5.000,00",
    price: 31.29,
  }

];

export const PRICE_OPTIONS_SAFE_MONTHLY = [
  {
    Title: "Ouro",
    id: 1,
    type: "mês",
    value: "10.000,00",
    price: 5.22,
  },
  {
    Title: "Prata",
    id: 2,
    type: "mês",
    value: "5.000,00",
    price: 2.61,
  }

];

export const PRICE_OPTIONS_WEEK = [
  {
    Title: "Prata WEEK",
    id: 1,
    type: "sem",
    price: 200.0,
    sale: true,
    saleValue: '15% Off',
    benefits: [
      { name: "Roubo e furto qualificado", checked: true },
      { name: "Quebra por acidente", checked: false },
      { name: "Responsabilidade Civil", checked: false },
      { name: "Incêndio", checked: false },
      { name: "Queda de raio", checked: true },
      { name: "Explosão", checked: false },
      { name: "Colisão", checked: true },
      { name: "Danos decorrentes de transporte", checked: true },
    ],
    extraBenefits: [
      { "name": "25% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": true, "icon": "check" },
      { "name": "Transporte e reboque", "checked": true, "icon": "check" },
      { "name": "Personal fitness", "checked": true, "icon": "check" },
      { "name": "Reparo", "checked": true, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": true, "icon": "check" },
      { "name": "Manutenção", "checked": false, "icon": "check" },
      { "name": "Chaveiro", "checked": false, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": false, "icon": "check" }
    ]
  },
  {
    Title: "Ouro",
    id: 2,
    sale: true,
    saleValue: '15% Off',
    type: "sem",
    price: 300.0,
    benefits: [
      { name: "Roubo e furto qualificado", checked: true },
      { name: "Quebra por acidente", checked: false },
      { name: "Responsabilidade Civil", checked: false },
      { name: "Incêndio", checked: false },
      { name: "Queda de raio", checked: false },
      { name: "Explosão", checked: false },
      { name: "Colisão", checked: true },
      { name: "Danos decorrentes de transporte", checked: true },
    ],
    extraBenefits: [
      { "name": "25% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": true, "icon": "check" },
      { "name": "Transporte e reboque", "checked": true, "icon": "check" },
      { "name": "Personal fitness", "checked": true, "icon": "check" },
      { "name": "Reparo", "checked": true, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": true, "icon": "check" },
      { "name": "Manutenção", "checked": false, "icon": "check" },
      { "name": "Chaveiro", "checked": false, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": false, "icon": "check" }
    ]
  },
  {
    Title: "Diamante",
    id: 3,
    type: "sem",
    sale: true,
    saleValue: '15% Off',
    price: 400.0,
    benefits: [
      { name: "Roubo e furto qualificado", checked: true },
      { name: "Quebra por acidente", checked: false },
      { name: "Responsabilidade Civil", checked: true },
      { name: "Incêndio", checked: false },
      { name: "Queda de raio", checked: false },
      { name: "Explosão", checked: false },
      { name: "Colisão", checked: true },
      { name: "Danos decorrentes de transporte", checked: true },
    ],
    extraBenefits: [
      { "name": "25% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": true, "icon": "check" },
      { "name": "Transporte e reboque", "checked": true, "icon": "check" },
      { "name": "Personal fitness", "checked": true, "icon": "check" },
      { "name": "Reparo", "checked": true, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": true, "icon": "check" },
      { "name": "Manutenção", "checked": false, "icon": "check" },
      { "name": "Chaveiro", "checked": false, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": false, "icon": "check" }
    ]
  },
];

export const PRICE_OPTIONS_MONTHLY = [
  {
    Title: "Diamante",
    id: 6,
    type: "mês",
    sale: true,
    saleValue: '15% Off',
    price: 30.62,
    mktPrice: 30.62,
    benefits: [
      { name: "Roubo e furto qualificado", checked: false },
      { name: "Quebra por acidente", checked: false },
      { name: "Responsabilidade Civil", checked: false },
      { name: "Incêndio", checked: false },
      { name: "Queda de raio", checked: false },
      { name: "Explosão", checked: false },
      { name: "Colisão", checked: false },
      { name: "Danos decorrentes de transporte", checked: false },
    ],
    extraBenefits: [
      { "name": "15% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": true, "icon": "check" },
      { "name": "Transporte e reboque", "checked": true, "icon": "check" },
      { "name": "Personal fitness", "checked": true, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": true, "icon": "check" },
      { "name": "Reparo", "checked": true, "icon": "check" },
      { "name": "Manutenção", "checked": true, "icon": "check" },
      { "name": "Chaveiro", "checked": true, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": true, "icon": "check" }
    ]
  },
  {
    Title: "Ouro",
    id: 5,
    sale: true,
    saleValue: '15% Off',
    type: "mês",
    price: 26.6,
    benefits: [
      { name: "Roubo e furto qualificado", checked: false },
      { name: "Quebra por acidente", checked: false },
      { name: "Responsabilidade Civil", checked: true },
      { name: "Incêndio", checked: true },
      { name: "Queda de raio", checked: true },
      { name: "Explosão", checked: true },
      { name: "Colisão", checked: true },
      { name: "Danos decorrentes de transporte", checked: true },
    ],
    extraBenefits: [
      { "name": "15% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": true, "icon": "check" },
      { "name": "Transporte e reboque", "checked": true, "icon": "check" },
      { "name": "Personal fitness", "checked": false, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": false, "icon": "check" },
      { "name": "Reparo", "checked": false, "icon": "check" },
      { "name": "Manutenção", "checked": false, "icon": "check" },
      { "name": "Chaveiro", "checked": false, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": false, "icon": "check" }
    ]
  },
  {
    Title: "Prata",
    id: 4,
    type: "mês",
    sale: true,
    saleValue: '15% Off',
    price: 24.25,
    mktPrice: 30.62,
    benefits: [
      { name: "Roubo e furto qualificado", checked: false },
      { name: "Quebra por acidente", checked: true },
      { name: "Responsabilidade Civil", checked: true },
      { name: "Incêndio", checked: true },
      { name: "Queda de raio", checked: true },
      { name: "Explosão", checked: true },
      { name: "Colisão", checked: true },
      { name: "Danos decorrentes de transporte", checked: true },
    ],
    extraBenefits: [
      { "name": "15% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": false, "icon": "check" },
      { "name": "Transporte e reboque", "checked": false, "icon": "check" },
      { "name": "Personal fitness", "checked": false, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": false, "icon": "check" },
      { "name": "Reparo", "checked": false, "icon": "check" },
      { "name": "Manutenção", "checked": false, "icon": "check" },
      { "name": "Chaveiro", "checked": false, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": false, "icon": "check" }
    ]
  },

];

export const PRICE_OPTIONS_YEARLY = [
  {
    Title: "Diamante",
    id: 9,
    type: "ano",
    sale: true,
    saleValue: '25% Off',
    price: 500.0,
    mktPrice: 30.62,
    priceAcessory: null,
    benefits: [
      { name: "Roubo e furto qualificado", checked: false },
      { name: "Quebra por acidente", checked: false },
      { name: "Responsabilidade Civil", checked: false },
      { name: "Incêndio", checked: false },
      { name: "Queda de raio", checked: false },
      { name: "Explosão", checked: false },
      { name: "Colisão", checked: false },
      { name: "Danos decorrentes de transporte", checked: false },
    ],
    extraBenefits: [
      { "name": "25% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": true, "icon": "check" },
      { "name": "Transporte e reboque", "checked": true, "icon": "check" },
      { "name": "Personal fitness", "checked": true, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": true, "icon": "check" },
      { "name": "Reparo", "checked": true, "icon": "check" },
      { "name": "Manutenção", "checked": true, "icon": "check" },
      { "name": "Chaveiro", "checked": true, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": true, "icon": "check" }
    ]
  },
  {
    Title: "Ouro",
    id: 8,
    type: "ano",
    sale: true,
    saleValue: '25% Off',
    price: 500.0,
    mktPrice: 30.62,
    benefits: [
      { name: "Roubo e furto qualificado", checked: false },
      { name: "Quebra por acidente", checked: false },
      { name: "Responsabilidade Civil", checked: true },
      { name: "Incêndio", checked: true },
      { name: "Queda de raio", checked: true },
      { name: "Explosão", checked: true },
      { name: "Colisão", checked: true },
      { name: "Danos decorrentes de transporte", checked: true },
    ],
    extraBenefits: [
      { "name": "25% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": true, "icon": "check" },
      { "name": "Transporte e reboque", "checked": true, "icon": "check" },
      { "name": "Personal fitness", "checked": false, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": false, "icon": "check" },
      { "name": "Reparo", "checked": false, "icon": "check" },
      { "name": "Manutenção", "checked": false, "icon": "check" },
      { "name": "Chaveiro", "checked": false, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": false, "icon": "check" }
    ]
  },
  {
    Title: "Prata",
    id: 7,
    type: "ano",
    sale: true,
    saleValue: '25% Off',
    price: 500.0,
    mktPrice: 30.62,
    benefits: [
      { name: "Roubo e furto qualificado", checked: false },
      { name: "Quebra por acidente", checked: true },
      { name: "Responsabilidade Civil", checked: true },
      { name: "Incêndio", checked: true },
      { name: "Queda de raio", checked: true },
      { name: "Explosão", checked: true },
      { name: "Colisão", checked: true },
      { name: "Danos decorrentes de transporte", checked: true },
    ],
    extraBenefits: [
      { "name": "25% de desconto", "checked": true, "icon": "check" },
      { "name": "Assistência 24h", "checked": false, "icon": "check" },
      { "name": "Transporte e reboque", "checked": false, "icon": "check" },
      { "name": "Personal fitness", "checked": false, "icon": "check" },
      { "name": "Assistência Nutricional", "checked": false, "icon": "check" },
      { "name": "Reparo", "checked": false, "icon": "check" },
      { "name": "Manutenção", "checked": false, "icon": "check" },
      { "name": "Chaveiro", "checked": false, "icon": "check" },
      { "name": "Ext. para transporte internacional", "checked": false, "icon": "check" }
    ]
  },


];

export const MODALITY_OPTIONS = [
  {
    "id": "3d125ebe-0b8f-4168-bfef-429d5070a3b2",
    "nome": "Mountain Bike"
  },
  {
    "id": "4f8c232b-988a-4ace-becd-7717d231a323",
    "nome": "Estrada | Speed | Road"
  },
  {
    "id": "09aded10-1f84-49f1-9a62-ab8ee2b4fec1",
    "nome": "Elétrica"
  },
  {
    "id": "4d80d626-03a6-4cdc-adf0-baecc91e0f3c",
    "nome": "Urbana"
  },
  {
    "id": "57815a09-b3c8-4e4a-9799-5552aff18be1",
    "nome": "Bmx | Cross"
  },
  {
    "id": "44a84e41-ad13-4d6b-89a1-f58524a430aa",
    "nome": "Triatlo"
  },
  {
    "id": "d8b333b7-0cc5-4e31-a0bf-a5bb2d6682c5",
    "nome": "Híbrida"
  },
  {
    "id": "dae705b5-73f7-40ec-9ecd-604f601e67af",
    "nome": "Triciclo"
  },
  {
    "id": "ee0a11ee-c28d-44f9-a69e-e044824da77f",
    "nome": "Downhill"
  },
  {
    "id": "1f944eaa-07d3-414c-aada-8eab7fc609b4",
    "nome": "Infantil"
  },
  {
    "id": "209ef0b1-5ae2-4b54-8ef6-8d82bff321cd",
    "nome": "Handbike"
  },
  {
    "id": "15098117-c8c7-4f4d-943e-625e1a943a8d",
    "nome": "Dobrável"
  },
  {
    "id": "e1350304-b3e5-42ce-8c6d-b533a6e773ad",
    "nome": "Scooter"
  },
  {
    "id": "4a631509-d5bd-483d-93c8-d40d157e3d6d",
    "nome": "Outros"
  },
  {
    "id": "55c50011-6f25-4775-9fe5-7a420fd54581",
    "nome": "Gravel"
  }
];

export const YEAR_BIKE_OPTIONS = [
  { AnoBike: 2024 },
  { AnoBike: 2023 },
  { AnoBike: 2022 },
  { AnoBike: 2021 },
  { AnoBike: 2020 },
  { AnoBike: 2019 },
  { AnoBike: 2018 },
  { AnoBike: 2017 },
  { AnoBike: 2016 },
  { AnoBike: 2015 },
  { AnoBike: 2014 },
  { AnoBike: 2013 },
  { AnoBike: 2012 },
  { AnoBike: 2011 },
  { AnoBike: 2010 },
  { AnoBike: 2009 }
];

export const BRANDS = [
  {
    id: "0c0b25c7-4c40-461a-b612-e4f292586542",
    nome: "24SEVEN",
  },
  {
    id: "b6ddee23-9fbe-412e-8d33-b484e34c4f42",
    nome: "ABICI",
  },
  {
    id: "2bb0fdeb-859d-4805-a753-28189914e630",
    nome: "ABSOLUTE",
  },
  {
    id: "972c6ffb-bd8f-46e2-b2e2-e84407c3e860",
    nome: "ACADEMY",
  },
  {
    id: "49865a09-a2a3-4c65-962f-6e5ed881b32a",
    nome: "ADVENTURE",
  },
  {
    id: "975d8674-88f8-4e80-813d-110826308821",
    nome: "AEGIS",
  },
  {
    id: "08f40b62-1fd6-441c-acbe-94ad1b7a2620",
    nome: "AEROTECH",
  },
  {
    id: "da3308df-016f-4261-a22e-10e173c45c32",
    nome: "AIRBORNE",
  },
  {
    id: "54bfed96-5caa-49c9-aab8-bf10d80c7144",
    nome: "AIRWALK",
  },
  {
    id: "98de0472-4faa-4799-ac92-b4d988574a59",
    nome: "AKRON",
  },
  {
    id: "f2231c29-1a07-4185-bb13-f0201e680ed8",
    nome: "ALEOCA",
  },
  {
    id: "521a6d7f-4d29-4a60-bb4f-34ce29267148",
    nome: "ALFAMEQ",
  },
  {
    id: "c97fe9c2-43a5-40be-a78c-3a6f5de18b75",
    nome: "ALL-CITY",
  },
  {
    id: "2768d5ec-fe32-410a-a591-591e0206ba47",
    nome: "AMF GLORIA MILANO",
  },
  {
    id: "74d61d4c-5e81-41b0-939c-f727a4d31bd2",
    nome: "APEX",
  },
  {
    id: "0a49535c-6e47-4bab-948a-0337cbbd4188",
    nome: "APOLLO",
  },
  {
    id: "e05224c8-616a-4b33-9d5d-9983868cf50c",
    nome: "ARGON 18",
  },
  {
    id: "33e5c94b-ae0a-4dcc-9f12-d457aa45dfea",
    nome: "ASDADS",
  },
  {
    id: "f1c4b558-56db-4222-aaa2-218394a3068f",
    nome: "ASPRILLE",
  },
  {
    id: "08a9cdd2-0b6d-455b-8758-67d6f19103bf",
    nome: "ASTRO",
  },
  {
    id: "13decf0d-d264-4921-bac8-6dcf6058fc36",
    nome: "ATALA",
  },
  {
    id: "6c7525eb-4d1f-4781-a440-497d1a716cc3",
    nome: "ATHOR",
  },
  {
    id: "41edf42e-a49d-4d16-88ed-ffef752798f7",
    nome: "AUDAX",
  },
  {
    id: "ca8aad20-41b0-4ec7-9394-0100a7f6dee6",
    nome: "AVALON",
  },
  {
    id: "7905fed6-a330-4d20-b022-e740664a7c29",
    nome: "AVANTI",
  },
  {
    id: "51371ca7-7e2a-4ba6-afdd-907a84a91a6a",
    nome: "AXXIS",
  },
  {
    id: "43030832-1b49-4fac-986f-87259b2b3242",
    nome: "AZONIC",
  },
  {
    id: "65c1520b-4ca9-4ffc-86f4-59c24bb1c609",
    nome: "BACINI",
  },
  {
    id: "19b20881-172a-41ed-b11a-48de91cc9065",
    nome: "BALANCE BUDDY",
  },
  {
    id: "d57ac956-7ec5-4c6f-a590-9570c962963a",
    nome: "BALLISTIC",
  },
  {
    id: "5bc53b1c-3d15-4100-bbdf-5f2fc8c84d57",
    nome: "BAMBU BIKE BRASIL",
  },
  {
    id: "5649e1b1-a0bd-4f33-bd77-42deaacf0380",
    nome: "BAMF",
  },
  {
    id: "ab180230-599b-40ad-ba4c-160eb9b1ff22",
    nome: "BANSHEE",
  },
  {
    id: "1d0f0b91-1c74-4b0e-ae51-62997c86ef7a",
    nome: "BASSO",
  },
  {
    id: "fa3a5d14-c10f-495c-9d42-b53dea7c870f",
    nome: "BEONE",
  },
  {
    id: "025ef547-311f-4166-9f22-fa45a6c9e9ea",
    nome: "BERG",
  },
  {
    id: "55669a32-d4e6-48d0-af4d-327933946ca1",
    nome: "BERGAMONT",
  },
  {
    id: "decd3f15-2b34-4d55-9e54-f8c1fdf04583",
    nome: "BERNARDI",
  },
  {
    id: "c8b2f0cb-4035-47a8-9561-86d3dddb96c1",
    nome: "BERRIA ",
  },
  {
    id: "a8c62484-e835-4a18-ab5d-1fd755424310",
    nome: "BFOLD",
  },
  {
    id: "7f07e815-02de-4a7d-8813-30bf11238401",
    nome: "BH",
  },
  {
    id: "a3425b0b-24ad-4b75-822c-b5c1d9ccca75",
    nome: "BIANCHI",
  },
  {
    id: "2b29ba69-37f9-4088-92ef-8bc93d635402",
    nome: "BIANINI",
  },
  {
    id: "b615a385-5a8c-4c79-a150-2bca4d40eead",
    nome: "BICIMOTO",
  },
  {
    id: "912c24f9-7928-4952-b760-fbbe9f068fd3",
    nome: "BIKE HAUS",
  },
  {
    id: "a8dec224-800e-485f-9345-1c7ba6a9893f",
    nome: "BIKE MANUFAKTUR",
  },
  {
    id: "c22f3e0d-7db6-4124-8650-eed8e6a593a3",
    nome: "BIKELAND",
  },
  {
    id: "359e02b6-7056-4252-add3-92a136c1c6d5",
    nome: "BIOBIKE",
  },
  {
    id: "baf28f12-b521-4a84-af8f-d350d330ee7f",
    nome: "BIOMEGA ",
  },
  {
    id: "e6fccc04-4db3-456f-949e-a20caaa6f014",
    nome: "BKL",
  },
  {
    id: "a63f88b3-5295-46eb-8d0b-43cb84749dd8",
    nome: "BLACK FIGHTER",
  },
  {
    id: "4d15decc-3348-4b09-b1e1-d1f873f62305",
    nome: "BLACK FLEA",
  },
  {
    id: "bedf4010-b74a-4c7f-8df2-5880387b6d63",
    nome: "BLANK",
  },
  {
    id: "a3cfb5c6-194a-4fba-baf3-0ef52d22093c",
    nome: "BLITZ",
  },
  {
    id: "ec769514-e209-4b36-bed5-6c393fd133f1",
    nome: "BLUE",
  },
  {
    id: "a141f532-3de7-4b4a-9eac-2b0e188ed655",
    nome: "BMC",
  },
  {
    id: "72524609-e2bb-499c-9742-e80220a5a9ba",
    nome: "BMW",
  },
  {
    id: "480fc645-bdae-45a2-911a-1977efdb10f2",
    nome: "BOARDMAN",
  },
  {
    id: "427fedc4-3aa0-4a88-b924-e0e0c08e4bb9",
    nome: "BOHEMIAN",
  },
  {
    id: "d97b7001-1618-49c1-8252-171f3cf460ea",
    nome: "BONETTI",
  },
  {
    id: "9a5591b1-600c-438f-8137-de739e938054",
    nome: "BOOMERANG",
  },
  {
    id: "087dcbab-ffe4-4b0a-a1e8-b95eb80a2827",
    nome: "BORNIA & COX",
  },
  {
    id: "ad89ece9-1282-4b0b-ae7e-9895d426e142",
    nome: "BÖTTCHER",
  },
  {
    id: "136229c0-775a-46cd-9d57-d0fe656142b4",
    nome: "BOTTECCHIA",
  },
  {
    id: "99d5d60f-1f6b-4147-996b-ce13f7c0c193",
    nome: "BRAND X",
  },
  {
    id: "ac895bef-3705-4a5b-8250-55ca037377b6",
    nome: "BRAUNN",
  },
  {
    id: "989156e0-d061-4b1e-a45f-252224889561",
    nome: "BRAZIL ELECTRIC",
  },
  {
    id: "6e0baa26-f2d3-4553-9255-5739bf01c928",
    nome: "BREEZER",
  },
  {
    id: "23fcfd69-279d-4b88-946f-482c436d3eb6",
    nome: "BREMEM",
  },
  {
    id: "b87a72d4-e49d-46b9-8bee-30746fa78667",
    nome: "BRODIE",
  },
  {
    id: "c1a2ccbb-dde0-461b-8df0-737bf7121a59",
    nome: "BROMPTON",
  },
  {
    id: "ac6b26c3-c344-4d5c-9816-330bd305f9e9",
    nome: "BRUNETT",
  },
  {
    id: "5139a78d-d152-439b-b8a6-b16e15b23d36",
    nome: "BSA BICYCLES",
  },
  {
    id: "e1465f41-b1b2-4736-9b8e-fea446b075ec",
    nome: "BT",
  },
  {
    id: "f433b803-a20f-4f36-bfde-c69b63c419f8",
    nome: "BTWIN",
  },
  {
    id: "46933691-49a3-49b2-8b3f-de96c4a9fd1b",
    nome: "BULLS",
  },
  {
    id: "edfca1dc-b08c-4baf-bbfd-ae1563299fdc",
    nome: "BURNETT",
  },
  {
    id: "eba539f7-10ef-49aa-b2b5-1e8c47995444",
    nome: "CA NORCO",
  },
  {
    id: "34e08abf-cff8-4fec-b2ae-5247d1a2d409",
    nome: "CA ROCKY MOUNTAIN",
  },
  {
    id: "6fdfed82-23a9-4694-bbde-5787cc2d7f13",
    nome: "CADEX",
  },
  {
    id: "b15819ef-ca99-46d3-a43b-8d44871b57ac",
    nome: "CAIÇARA",
  },
  {
    id: "d5b1c7b2-39b3-4196-965e-8c90ee384b8d",
    nome: "CAIRU",
  },
  {
    id: "76874a79-611f-4f0c-827e-17be33b2c4db",
    nome: "CAIXA",
  },
  {
    id: "7ca85f87-7f3a-4f4e-a2f9-6d23001a2358",
    nome: "CALFEE",
  },
  {
    id: "1d96dd0f-21e5-4f22-ab7f-67e9148d6d74",
    nome: "CALOI",
  },
  {
    id: "e2a0ccbb-d172-4eb0-b43c-f1d0ad7a7b62",
    nome: "CALOI 2",
  },
  {
    id: "ad172d92-bb3b-48b9-94a2-aee74d9c2d0c",
    nome: "CALYPSO",
  },
  {
    id: "4996d32c-718e-4f37-b319-ed356fdf4566",
    nome: "CANADIAN",
  },
  {
    id: "15b81290-ca24-4757-9f32-453d89deabfa",
    nome: "CANFIELD",
  },
  {
    id: "470a5253-b105-4e46-9791-8ac7ad807a38",
    nome: "CANNON",
  },
  {
    id: "e0237840-9e5c-4217-92be-2d911871cc62",
    nome: "CANNONDALE",
  },
  {
    id: "e876018a-1686-40b3-8f52-03681cc899b7",
    nome: "CANYON",
  },
  {
    id: "143c5f3e-b57b-4d72-881f-3f03425dbcd0",
    nome: "CARDOSO",
  },
  {
    id: "4b745d50-3379-4a79-aa96-ea541ca738ff",
    nome: "CARRERA",
  },
  {
    id: "caf627d8-251c-4f2e-8552-3d778184f125",
    nome: "CARRY FREEDOM",
  },
  {
    id: "7b3cd9ef-b285-4a2c-8c20-59d3cd09c62c",
    nome: "CEEPO",
  },
  {
    id: "2ac78214-2c2c-4057-bcfa-18beb05c1cf1",
    nome: "CELL",
  },
  {
    id: "0ed43696-8e3b-46e3-9602-4643a4880f96",
    nome: "CERNUNNOS",
  },
  {
    id: "82a37b7b-e281-493b-b532-c6c8bac5a512",
    nome: "CERVÉLO",
  },
  {
    id: "9e924b22-942c-4148-8408-5d26e3a1798a",
    nome: "CHARGE",
  },
  {
    id: "dcfde5ad-7352-41f0-971a-a1eaa6c33591",
    nome: "CHASE",
  },
  {
    id: "1b5b64dd-05d2-486d-aa86-f3b70fe393df",
    nome: "CHILLIBEANS",
  },
  {
    id: "fc6fd5fc-33a5-448e-a259-da792fe619ed",
    nome: "CHRONOS",
  },
  {
    id: "0bf53f31-2278-423e-9309-e8999ec4b48c",
    nome: "CHUMBA RACING",
  },
  {
    id: "de34af88-90b4-4729-b964-10678704cc42",
    nome: "CIA BRASIL",
  },
  {
    id: "a6566155-a6bc-4dc6-b726-fab11dcff260",
    nome: "CICLARE",
  },
  {
    id: "9f0e6f8c-bf40-438a-992a-a1bff6cd3210",
    nome: "CICLÉG",
  },
  {
    id: "6f4e4686-0a02-431f-8c63-47b75e7e6572",
    nome: "CINELLI",
  },
  {
    id: "e76385fb-3383-463e-b3f9-3322d30f790d",
    nome: "CIPOLLINI",
  },
  {
    id: "73c76e28-72c1-46c4-9f42-57a0c51fcf37",
    nome: "CITIZEN",
  },
  {
    id: "6fc53420-afb9-451c-b45c-bc1131d066c8",
    nome: "CLAUD BUTLER",
  },
  {
    id: "a4fea573-e185-49fd-a720-de1293a2b0a8",
    nome: "CLY",
  },
  {
    id: "70efcd80-6723-4bfb-bd92-13fd11bc034b",
    nome: "COLLI",
  },
  {
    id: "32707a83-05e4-4faf-951d-7ecf783170b3",
    nome: "COLNAGO",
  },
  {
    id: "90359752-a021-41b3-b529-e249b9b93cf2",
    nome: "COLNER",
  },
  {
    id: "201bf683-0e78-473d-9076-9e78134b19e1",
    nome: "COLONY",
  },
  {
    id: "92f4d8e9-08c4-4a75-8815-e5ae4e34ecde",
    nome: "COLOR BIKE",
  },
  {
    id: "00e74a31-0ded-4d65-9a27-4e56d7fed9cc",
    nome: "COLOSSI",
  },
  {
    id: "27605f1d-ac95-4049-b4ae-5f41dc68c1c5",
    nome: "COLUER",
  },
  {
    id: "c7642ee3-7943-4527-af10-bd1a615ff372",
    nome: "COMMENCAL",
  },
  {
    id: "d6dea6e8-827d-4cac-9e04-b40350dcd94b",
    nome: "CONDOR",
  },
  {
    id: "6e9b1857-cd9d-4780-92b4-2c7f5bd35184",
    nome: "CONOR",
  },
  {
    id: "4a5087f4-4a2b-40f0-a1f4-d75ac3f6a39d",
    nome: "CORIMA",
  },
  {
    id: "ce2431d0-c05f-4a41-872d-a67232a86513",
    nome: "CORRATEC",
  },
  {
    id: "1cac2c17-e638-4686-b1db-fdf326c2e537",
    nome: "CORTINA",
  },
  {
    id: "e3e0b956-5feb-46c9-a49b-e781f3307893",
    nome: "COZAC",
  },
  {
    id: "00246052-ba98-4df5-971a-0bd130654d94",
    nome: "CREATE",
  },
  {
    id: "a04f0c00-0e42-4842-88ae-18da0472d27e",
    nome: "CREME",
  },
  {
    id: "9e2211bd-3d26-4a98-8477-06356c78bb6d",
    nome: "CRONUS",
  },
  {
    id: "fe3aa564-c01c-4915-b533-5eea3e793d62",
    nome: "CRUZBIKE",
  },
  {
    id: "ba5708b9-b664-47cc-b40e-b64004d8f9ce",
    nome: "CUBE",
  },
  {
    id: "eb85eae1-b8f8-4156-a3b8-94721d101ed6",
    nome: "CULT",
  },
  {
    id: "2a1b2ef9-6813-4adb-88ef-e446f6c90d7a",
    nome: "CURRIE TECH",
  },
  {
    id: "6ea53fb1-79ec-4ce7-a143-f0ceaec838fb",
    nome: "CYCLETECH",
  },
  {
    id: "83124eca-452e-4b4c-a265-9b7b7db5368a",
    nome: "DA BOMB",
  },
  {
    id: "d1e469f9-2c53-40e5-b51c-ae4066c5e397",
    nome: "DACCORDI",
  },
  {
    id: "34d87bcd-97dd-46c7-94ea-b7f8bdf3ea9e",
    nome: "DAFRA",
  },
  {
    id: "2da14f70-88ba-4af5-8277-07452a2b225e",
    nome: "DAHON",
  },
  {
    id: "c5ba19fd-0a66-454d-9f3d-d6ac41ee19f4",
    nome: "DALANNIO",
  },
  {
    id: "08883854-ff20-41d5-aaf4-51c4821e54f0",
    nome: "DAWA",
  },
  {
    id: "3b3182f0-2b98-4704-92c9-ce7736bac4c9",
    nome: "DAWES",
  },
  {
    id: "50928f25-d451-4f60-81d0-7f9d9851377e",
    nome: "DE ROSA",
  },
  {
    id: "ca61a8d5-33d0-4749-92f0-b5871b3c6a35",
    nome: "DEAN",
  },
  {
    id: "aef7572c-f9a6-4558-a85f-5e6f62276ff6",
    nome: "DECATHLON",
  },
  {
    id: "e6b67ae6-6aa7-4e51-b6aa-c3c6b177333c",
    nome: "DEDACCIAI",
  },
  {
    id: "f60e3468-e039-41ee-aa67-a43a68a7c15a",
    nome: "DEKERF",
  },
  {
    id: "68c05a98-f531-4c89-a55b-c4f64df4c688",
    nome: "DEPEDAL",
  },
  {
    id: "1180ec9b-6d2f-4e13-bedc-4f57001ff315",
    nome: "DEVINCI",
  },
  {
    id: "1684302a-1a8d-45e2-86e0-cfc35458fff7",
    nome: "DIAMONDBACK",
  },
  {
    id: "5892ccd9-eeb6-4c52-aa6a-1bbedc7fb341",
    nome: "DITEC",
  },
  {
    id: "a62e93f2-2dbd-499a-9f54-7a95686628cd",
    nome: "DK",
  },
  {
    id: "6039eae4-16e7-4c0f-b5c8-99228f2e4e4d",
    nome: "DMS",
  },
  {
    id: "4db89be4-a344-45fc-971e-361ad6ac76e4",
    nome: "DNZ",
  },
  {
    id: "fbfacc80-6eff-4c97-81a1-4c81a57b32fb",
    nome: "DOLAN",
  },
  {
    id: "120dfb7c-3ddc-4727-8553-743fe877a5db",
    nome: "DOSNOVENTA",
  },
  {
    id: "d9d83361-409a-4732-85b1-2c6fcdc6464e",
    nome: "DOWNHILL",
  },
  {
    id: "224f6a04-cb08-4ffd-ac7b-45e2b76aded4",
    nome: "DREAMBIKE",
  },
  {
    id: "764afc8d-14d8-43c1-8fb6-13b4ffc024aa",
    nome: "DRÖSSIGER",
  },
  {
    id: "322754a8-9ad9-4810-9b5f-57fa2c11686a",
    nome: "DTFLY",
  },
  {
    id: "2b443727-5788-4591-b4ca-bd29b16967a3",
    nome: "DURBAN",
  },
  {
    id: "082be4c1-3eea-4c79-a531-833f7bdd7a34",
    nome: "DYNAMICS",
  },
  {
    id: "f8e97f18-4462-49a5-982f-0cf91689ff87",
    nome: "E-CLUB",
  },
  {
    id: "3f6ee549-b9d9-4d90-b2ce-5029ec557ec1",
    nome: "E-LEEZE",
  },
  {
    id: "86abc4d4-d278-4fcd-95bc-c146c19d5bda",
    nome: "EASTERN",
  },
  {
    id: "46577500-5a74-4c31-8adf-275973e6fdd8",
    nome: "ECHO VINTAGE",
  },
  {
    id: "10e849b4-2776-4d07-9f8c-fafabb65a9ff",
    nome: "ECOBIKE",
  },
  {
    id: "aabb6bce-6fd0-4a4f-8b13-1bdd05eae2ce",
    nome: "ECOS",
  },
  {
    id: "0ff96d2e-8e6e-4bc3-984f-9e3da5876f4d",
    nome: "ECOSTART",
  },
  {
    id: "643dec1f-7689-4c6b-ac62-bf609748f1a5",
    nome: "EDDY MERCKX",
  },
  {
    id: "f3fafece-8906-4fca-b074-735a723987ea",
    nome: "EIGHTBIKE",
  },
  {
    id: "aa11afb7-3710-487b-9d7c-e2970689e47d",
    nome: "EIGHTHINCH",
  },
  {
    id: "74d64c75-ffdc-41ca-a09f-d3f96d2efc35",
    nome: "ELECTRA",
  },
  {
    id: "4e9db385-043d-4b6e-9e8f-fc82fd4adcc3",
    nome: "ELLEVEN",
  },
  {
    id: "5b03e7ee-95ec-41ef-a4f9-caaafc4eb5d9",
    nome: "ELLSWORTH",
  },
  {
    id: "7a8aeb3e-be91-4478-8e19-d357dbdf9f42",
    nome: "ESSENCIAL",
  },
  {
    id: "f31e1e88-2b8e-4966-9868-c7d012b0760d",
    nome: "EVO",
  },
  {
    id: "3455ebb1-6e67-431d-ab51-cb1c865aad76",
    nome: "EVOKE",
  },
  {
    id: "32ce2f4f-dc07-402c-8514-f4ad161f585c",
    nome: "F. MOSER",
  },
  {
    id: "9eb1863d-90a5-426e-913c-9b9b259a84a4",
    nome: "FAILURE",
  },
  {
    id: "849eb3af-9367-400a-bab5-2d6523a7b90c",
    nome: "FELT",
  },
  {
    id: "61a4a8c2-a10c-472f-977b-34f45be81ba4",
    nome: "FENIX",
  },
  {
    id: "83dbfe00-c1bc-4505-9aa9-c4cd8229be83",
    nome: "FES",
  },
  {
    id: "76a21bc5-5138-4df3-bef3-6bab01aa41c9",
    nome: "FIB",
  },
  {
    id: "14fe4c68-0c1d-4ad4-a6c2-0f1627f9f722",
    nome: "FICTION",
  },
  {
    id: "31cf4798-413f-4720-a5df-9cea6f765993",
    nome: "FIRETECK",
  },
  {
    id: "013cb2f3-6b44-4c6d-8411-c4c4f0a73237",
    nome: "FIRMSTRONG",
  },
  {
    id: "d3692551-b7f9-4411-805f-9a31c552b939",
    nome: "FIRST",
  },
  {
    id: "5108afbb-c408-4f74-94c1-66821d5ee373",
    nome: "FISCHER",
  },
  {
    id: "77bbb807-10d5-4f7d-95c9-0114f0a54990",
    nome: "FIV5R",
  },
  {
    id: "946ebea2-2763-4d73-9234-ba2074704752",
    nome: "FLEE",
  },
  {
    id: "122a4602-9523-49ff-9a7e-6dea560f79cb",
    nome: "FLYXII",
  },
  {
    id: "3b9ff4ca-3601-4ce3-b277-2b0d93ca58ff",
    nome: "FOCUS",
  },
  {
    id: "5ca7e4a2-8aef-4bd9-9187-09c487175d3b",
    nome: "FOFFA",
  },
  {
    id: "f52b3847-9f0c-4b00-88c4-9ad6ddc18ad5",
    nome: "FONDRIEST",
  },
  {
    id: "5fb44dde-4559-4518-a99a-ecb2ccf1a46a",
    nome: "FORME",
  },
  {
    id: "41a519ef-7e58-4cb0-89b3-bd84b781f78b",
    nome: "FORSS",
  },
  {
    id: "a653b59f-14f8-462f-90c6-9b28cc0b96d6",
    nome: "FREEAGENT",
  },
  {
    id: "a857c574-74c7-4926-a5c9-a609c67bb7db",
    nome: "FUJI",
  },
  {
    id: "eb27cddd-b746-4fdc-a263-ce61052067ba",
    nome: "FUNRIDE",
  },
  {
    id: "e59c9539-5339-47a8-acb7-eb544f63df8b",
    nome: "GALILEUS",
  },
  {
    id: "87db6880-bec3-4e23-a72d-b2f532d72ca1",
    nome: "GALLO",
  },
  {
    id: "d6e81e8d-ad45-4c86-981f-4c01dea780df",
    nome: "GAMA",
  },
  {
    id: "ffb6a182-cd3f-42b8-869e-d943de74de0b",
    nome: "GARRA",
  },
  {
    id: "141aa080-bcfd-46e4-8db5-fa4a82709ec1",
    nome: "GARY FISHER",
  },
  {
    id: "87caa2c6-ef33-40fc-910e-e3fd2656365a",
    nome: "GAZELLE",
  },
  {
    id: "d73d506d-7552-4d96-8c4f-e1894c42ce47",
    nome: "GENERAL WINGS",
  },
  {
    id: "57101786-3f88-4571-98ff-5b9dbd277c96",
    nome: "GENESIS",
  },
  {
    id: "4f8048a2-3e2f-4d91-844d-c514d0da3e8f",
    nome: "GHAO",
  },
  {
    id: "6fc9dc85-8f65-4606-abc8-601f29d7917f",
    nome: "GHOST",
  },
  {
    id: "e24aa037-8a52-4282-91b3-7726d401d147",
    nome: "GIANT",
  },
  {
    id: "e4f771bb-ad35-444b-8d67-c33356c883ac",
    nome: "GILMEX",
  },
  {
    id: "9f65c474-89ca-475c-9613-f1b41707acc8",
    nome: "GIORDANO ",
  },
  {
    id: "300f6abf-ba6c-490a-bafc-e82055d8d577",
    nome: "GIOS",
  },
  {
    id: "3fdce4f8-2345-4e2e-b2c1-fc6d0f56fa30",
    nome: "GITANE",
  },
  {
    id: "eb4ed836-9e3a-48a3-a87e-12d393de8143",
    nome: "GMC",
  },
  {
    id: "b8f40f5f-cb60-4f3f-adc1-e4bee374599c",
    nome: "GO EASY",
  },
  {
    id: "192cdf62-d1c1-4b74-b7c8-ba5ba7e58bcf",
    nome: "GO SPORT",
  },
  {
    id: "d0c4c347-2d66-4415-98b4-1bde7d050029",
    nome: "GONEW",
  },
  {
    id: "e061773f-f590-4e36-9113-054b2b57d00d",
    nome: "GÖRICK",
  },
  {
    id: "121e6cb8-fbc6-4c6b-a88d-95183ead2f8c",
    nome: "GRAZIELLA",
  },
  {
    id: "01a26c58-a8bc-4567-bd1b-7927a520274e",
    nome: "GRECG",
  },
  {
    id: "1d4bf75c-9a93-4909-b0ec-ee826f630c8d",
    nome: "GRECO",
  },
  {
    id: "adaa5a79-c253-483a-adf4-313157ccaf76",
    nome: "GROOVE",
  },
  {
    id: "b4e1805e-8b28-4478-a644-029b1949e628",
    nome: "GT",
  },
  {
    id: "bbe5899e-2218-446c-bd9e-18bb56702d9f",
    nome: "GT MAX",
  },
  {
    id: "4980ed0f-ea70-4e18-98d3-e537a7e4dd5f",
    nome: "GT SUPER",
  },
  {
    id: "75cf8432-c140-46c6-b339-c7509cb9f6bc",
    nome: "GTA",
  },
  {
    id: "d7cc74ea-7420-41aa-a8b4-edf2281ac297",
    nome: "GTI",
  },
  {
    id: "726f6b9a-ef3f-40f3-94f9-ea0cace4a8e3",
    nome: "GTK",
  },
  {
    id: "ef6cda7e-db35-4bfc-a8ae-50e77eab9f9e",
    nome: "GTS",
  },
  {
    id: "a35c78f2-8e4b-4672-884a-02088496e36a",
    nome: "GTSM1",
  },
  {
    id: "16597fc1-29ec-4976-913f-2a278f8cbf64",
    nome: "GTW",
  },
  {
    id: "a1e1f4bf-a390-4768-af04-9940d5d65bb5",
    nome: "GTX",
  },
  {
    id: "455cc2b4-075b-47d5-85f0-c242780d4ef8",
    nome: "GTZ",
  },
  {
    id: "bbef5a33-0c45-4fc1-8177-2ab18d2ff95a",
    nome: "GUNNAR",
  },
  {
    id: "95a449ac-c650-4019-bc21-91a6803343d7",
    nome: "GURU",
  },
  {
    id: "4c7d4da4-73f9-4c0e-8e13-c941773656de",
    nome: "GW",
  },
  {
    id: "7e15796c-ab6e-4ea0-87d0-d081ccd245c6",
    nome: "HAIBIKE",
  },
  {
    id: "e6089baa-7014-4231-9569-ab35c2e4d615",
    nome: "HARO",
  },
  {
    id: "cd3318f8-8775-4006-86bd-a0ebe15feda1",
    nome: "HARPY",
  },
  {
    id: "f3310b2d-1049-46d2-9e15-f5636feeb933",
    nome: "HASA",
  },
  {
    id: "ca8a7ebb-cca9-4617-8649-ddf8e524a9ec",
    nome: "HEBEI TONGYI",
  },
  {
    id: "213e4bbc-414c-47b7-a642-8e192c7ca5a6",
    nome: "HEILAND",
  },
  {
    id: "d2be7c44-85fb-495b-a928-42c54342ec82",
    nome: "HERCULES",
  },
  {
    id: "c2ba5e10-e438-4e1c-bed0-ab00dedba72f",
    nome: "HÍBRIDA",
  },
  {
    id: "d88cdcd3-5a9d-43d9-8185-0b9813f74c18",
    nome: "HIGH ONE",
  },
  {
    id: "5493377d-d2aa-4b71-9664-923c43b55413",
    nome: "HOFFMAN",
  },
  {
    id: "74b156fe-31c6-4741-81aa-d30866a1bee1",
    nome: "HONGFU",
  },
  {
    id: "2183a656-79cc-4277-b0ba-5632a75b32c9",
    nome: "HÓRIZOM",
  },
  {
    id: "df5a1a30-4b79-4f4d-8a9e-2bea67fed2d4",
    nome: "HOUSTON",
  },
  {
    id: "b202935d-e9fe-4a6d-909c-3e52fed4d7ba",
    nome: "HOWMER",
  },
  {
    id: "c0daa1c1-3366-4361-a118-314db8133791",
    nome: "HUBU",
  },
  {
    id: "42ba9a3c-c307-46de-aec0-9a8f0d2e0cc3",
    nome: "HUFFY",
  },
  {
    id: "eb041986-d473-49a4-89c1-1e6010aa23f9",
    nome: "HUMMER",
  },
  {
    id: "cc90f447-8859-4fce-b65e-683716168ea1",
    nome: "HUPI",
  },
  {
    id: "d2584067-4e90-4f58-a639-cc684503493c",
    nome: "HYPER",
  },
  {
    id: "e1ca17ad-df56-4e86-b5d0-4b94a54da008",
    nome: "IBIS",
  },
  {
    id: "9a90bffb-197b-49f1-879d-6797d85332bb",
    nome: "IDENTITI",
  },
  {
    id: "8d2eae97-a9f3-4519-b8e2-0a5b24200247",
    nome: "INTENSE",
  },
  {
    id: "e6e002c3-74a9-41b3-9457-1e7bcb6100ff",
    nome: "IRONHORSE",
  },
  {
    id: "57bd58b3-e949-4ecf-9f4b-51216c99ff1a",
    nome: "JAMIS",
  },
  {
    id: "e957e526-3e5f-41d8-91c4-a7893ba1a857",
    nome: "JBC PRO",
  },
  {
    id: "08150099-468f-4620-a7e1-851e2ca410fe",
    nome: "JBTECH",
  },
  {
    id: "82458750-51ee-4e6e-b107-605cf39d36f2",
    nome: "JEEP",
  },
  {
    id: "07e31f63-9cce-4ed4-9106-af5792a719c6",
    nome: "JLS",
  },
  {
    id: "e199eff5-b0fd-407c-ac62-fb93dd8da35d",
    nome: "JNA",
  },
  {
    id: "8af1184c-e7dd-44c4-9040-7b3b278260dc",
    nome: "JOHNNY LOCO",
  },
  {
    id: "117806e1-e68d-4cef-a044-2e3d1410569b",
    nome: "K2",
  },
  {
    id: "60192cfb-868c-4e91-9d1a-6020f43146d5",
    nome: "KALF",
  },
  {
    id: "686dada6-3768-43a9-a15e-8febd387bb74",
    nome: "KAPA",
  },
  {
    id: "a342d85e-69a9-489e-a196-0dbd22ea1e5f",
    nome: "karakoram",
  },
  {
    id: "8c36ea99-c1b8-4ead-bac3-166c2f5d4eeb",
    nome: "KASINSKY",
  },
  {
    id: "2fdc72c8-9253-4d79-9683-28242839dcf0",
    nome: "KAWASAKI",
  },
  {
    id: "df3e38bc-81b9-4692-b183-745393550ecc",
    nome: "KEBI",
  },
  {
    id: "7ff134c9-de56-4f74-b336-2f2c334a0405",
    nome: "KEEN",
  },
  {
    id: "a1efbf08-2155-4edf-9fb5-2c963731f78f",
    nome: "KENSTONE",
  },
  {
    id: "7dd9af7c-51eb-4f16-be1b-5f4d1c66bb01",
    nome: "KENT",
  },
  {
    id: "082e82ef-ec89-40e1-96da-0f21e180f4f4",
    nome: "KESTREL",
  },
  {
    id: "83e46ff7-c426-44ec-b25d-edb3c7289f15",
    nome: "KHS",
  },
  {
    id: "27caa813-7b6b-4f3e-b8c9-9ed7ba766f84",
    nome: "KHT",
  },
  {
    id: "a1f39c45-900c-42be-8af5-0f91ec2882e2",
    nome: "KIDDIMOTO",
  },
  {
    id: "ebd92e39-6a8a-49e1-a515-8011d5306d18",
    nome: "KINESIS",
  },
  {
    id: "eb44d9c1-7f72-4ef1-b233-82111e2cd27e",
    nome: "KINK",
  },
  {
    id: "457f874b-5499-47ae-b1f8-2c07a1af5518",
    nome: "KLEIN",
  },
  {
    id: "caa0ce10-6e6a-4c9d-9e3d-19b126b77047",
    nome: "KMT",
  },
  {
    id: "27d94045-6586-4ea0-baa1-dbc85c348c40",
    nome: "KODE",
  },
  {
    id: "420d8eb0-0d28-4a8e-ac9f-d3f50216b5ef",
    nome: "KOGA",
  },
  {
    id: "6756987d-0204-40d7-9f29-7e975a34ee01",
    nome: "KOMBAT",
  },
  {
    id: "643c9ee8-a8c4-4e84-954c-b4e4aff4c01f",
    nome: "KONA",
  },
  {
    id: "824e9630-8295-431b-9385-f33b6c89164c",
    nome: "KRONUS",
  },
  {
    id: "5bb2b111-8db8-4be2-bac7-90e216ac6dd2",
    nome: "KROSS",
  },
  {
    id: "2436b16e-e619-4cbe-99bc-e0e9067d8414",
    nome: "KRS",
  },
  {
    id: "9e788bdd-8142-41af-809a-eb61935043bc",
    nome: "KSW",
  },
  {
    id: "762d03d6-89fe-4222-b7f6-bd6de65c206f",
    nome: "KTM",
  },
  {
    id: "bf49565c-bb5c-4580-b29d-2f961fb823e5",
    nome: "KULANA",
  },
  {
    id: "571323f7-9841-495c-833b-ad65f12da508",
    nome: "KUOTA",
  },
  {
    id: "f67a3a3b-177f-43f2-a8f6-17205f4460e3",
    nome: "KURUMA",
  },
  {
    id: "87bfdac6-0a96-4e77-aa85-3bf1f9178524",
    nome: "KUSTOMBIKE",
  },
  {
    id: "a453e246-8aae-4aa0-afc9-608693313037",
    nome: "KYLIN",
  },
  {
    id: "539601c4-dc56-4157-a548-c8add6e26af6",
    nome: "LA BICI ",
  },
  {
    id: "35b597f5-369d-419c-aff3-5482e6aeae9d",
    nome: "LA DRACO",
  },
  {
    id: "bf10a531-7056-4b15-957a-6515469fc206",
    nome: "LAHSEN",
  },
  {
    id: "c89d47a6-b1dc-4598-b58e-4467208b18e7",
    nome: "LAND ROVER",
  },
  {
    id: "80450d13-3e03-46d8-aab2-1d956335ccbd",
    nome: "LANGTU",
  },
  {
    id: "ba245e0d-9b4a-49d6-a931-a427eab7034c",
    nome: "LAPIERRE",
  },
  {
    id: "d48fc389-a473-41d6-b7c6-43204618accc",
    nome: "LEE COUGAN",
  },
  {
    id: "9ad5fb84-94c0-403e-960c-f0d8ae2584a5",
    nome: "LEMOND",
  },
  {
    id: "d29146a1-da7f-4902-b54b-003df8de112f",
    nome: "LEV",
  },
  {
    id: "8d3b3cdd-cd05-43df-bbe4-3eb56f9f48f5",
    nome: "LINUS",
  },
  {
    id: "22f936ff-78e6-4f2f-8cb6-9e1b5fc4dc64",
    nome: "LITESPEED",
  },
  {
    id: "ccd95a13-0ce5-45d3-ae50-052ab6823671",
    nome: "LOOK",
  },
  {
    id: "c3334c14-e280-4396-aa8d-3a651962d273",
    nome: "LOTUS",
  },
  {
    id: "3482e910-ed22-43f6-9758-206cebd2b409",
    nome: "LTX",
  },
  {
    id: "06d469b2-da09-4831-8fb4-aec00d7feea0",
    nome: "LXR",
  },
  {
    id: "2cde764c-3b9b-40f0-8550-986052ab0411",
    nome: "LYNSKEY",
  },
  {
    id: "56539f21-3b5c-4913-ada6-1c7cd14b2699",
    nome: "M ROSSI",
  },
  {
    id: "62c17f9e-49cc-41b0-939a-3e3b75a650dc",
    nome: "M7",
  },
  {
    id: "37318eeb-d2a0-47d5-9854-b07a85da04fe",
    nome: "MAGIAS",
  },
  {
    id: "5538e660-4921-410e-856e-abd3c23c5693",
    nome: "MAGNA",
  },
  {
    id: "d2e8585a-9b4a-404b-b02f-5861dd153077",
    nome: "MARCA CHARLES",
  },
  {
    id: "c7c21897-a6c3-4e06-a46b-c5940370dafb",
    nome: "MARCA TESTE",
  },
  {
    id: "670bdeb7-099c-411a-9a20-516e4c70691f",
    nome: "MARIN BIKES",
  },
  {
    id: "a4d7916c-8f5f-44c8-a5b4-9b7ce3cf7526",
    nome: "MARSSTAR",
  },
  {
    id: "99c5a749-e33e-4026-9979-557af25abc83",
    nome: "MARTIN",
  },
  {
    id: "5af18db8-b212-4750-a7b4-2b0b72ad8b7f",
    nome: "MASCOT",
  },
  {
    id: "04fd6cfe-9bd2-43f3-aba8-6dc5f3d75d96",
    nome: "MASI",
  },
  {
    id: "5ae0ee44-4390-4ba0-a2ac-f00ac10f03df",
    nome: "MASTER BIKE",
  },
  {
    id: "b68fa36d-1f0d-491f-a2a0-88b22fe9ac04",
    nome: "MAZZA",
  },
  {
    id: "08cb653a-c71d-44d6-a8f3-98729f0e70c5",
    nome: "MBK",
  },
  {
    id: "d14f9a99-5191-4892-a59e-f3f5b8bc87c5",
    nome: "MDI",
  },
  {
    id: "b5552542-5c83-4122-b610-cae6bea382a8",
    nome: "MEGA",
  },
  {
    id: "3fbddd17-7911-4b66-a08f-8cba23ded98c",
    nome: "MERAUD",
  },
  {
    id: "503dde41-49eb-4268-98ba-8d9cb8426cf9",
    nome: "MERCIER",
  },
  {
    id: "605af50a-e4cb-40e1-9478-7dab93970c34",
    nome: "MERIDA",
  },
  {
    id: "8780fc59-0b6e-46b8-81d0-76fc48d8c114",
    nome: "MERLIN",
  },
  {
    id: "8d9d2675-ba5b-49ec-b421-d3204c82321a",
    nome: "MIFA",
  },
  {
    id: "9ce50d45-eb50-4fdc-b821-42a455dcd261",
    nome: "MITICAL",
  },
  {
    id: "12a71f8f-aac2-426f-9a5d-1d82f8db4830",
    nome: "MITSU",
  },
  {
    id: "74fc79cb-eada-4d5e-a4d2-b10f96168ac7",
    nome: "MIYAMURA",
  },
  {
    id: "297987fc-63b5-4873-8671-e6773eef138f",
    nome: "MOB",
  },
  {
    id: "77180185-0978-4fe0-82a2-dd783773d25e",
    nome: "MOBELE",
  },
  {
    id: "d1411c7e-3c2d-4c4e-b55e-cc998c731f93",
    nome: "MODE",
  },
  {
    id: "c4c2956b-f2ab-41f2-8841-75a8ec785e9a",
    nome: "MONACO",
  },
  {
    id: "84ac3870-ec12-428b-ba87-6c938826bc50",
    nome: "MONARK",
  },
  {
    id: "0be3a550-aa2c-49fe-95d4-7302ba84d215",
    nome: "MONDAINE",
  },
  {
    id: "60b94ef5-e0ff-4997-85c6-9da24e4c3c45",
    nome: "MONDRAKER",
  },
  {
    id: "8db5336f-ae36-46df-9ff5-107608bb53ea",
    nome: "MONGOOSE",
  },
  {
    id: "fceaa361-7633-489f-baf4-647984da03cf",
    nome: "MONTAGUE",
  },
  {
    id: "29207af7-e338-4514-8da8-9988d8e2a8bb",
    nome: "MONTY",
  },
  {
    id: "1312dd2b-899d-45f0-a5fb-b559f1737432",
    nome: "MOOD",
  },
  {
    id: "7b8d9b19-4b65-4b6f-8a42-4f3834ca3693",
    nome: "MOOTS",
  },
  {
    id: "afe09ebb-12bf-4359-a170-884614a7bf90",
    nome: "MORMAII",
  },
  {
    id: "068d2954-cf97-49c9-9652-5f4af762b048",
    nome: "MOSER",
  },
  {
    id: "bb2e8c26-9a9a-4a51-8efa-dbac9c76ede4",
    nome: "MOSSO",
  },
  {
    id: "978eec89-d750-448d-898e-8867ff58e7d3",
    nome: "MOTACHIE",
  },
  {
    id: "3d434d65-707c-4ad4-adc5-3403accf298b",
    nome: "MOTOBECANE",
  },
  {
    id: "aeabb2af-5e43-464b-b27a-ec26be41178e",
    nome: "MOULTON",
  },
  {
    id: "4b94e92e-11e1-493e-a774-98f9177caeb6",
    nome: "MOUNTAIN CYCLE",
  },
  {
    id: "9c39ae42-4f4a-4088-a613-c7be0a006bda",
    nome: "MULTILASER",
  },
  {
    id: "75a1c8cf-fb3c-4298-80a2-1d37ed4ab6e9",
    nome: "MURRAY",
  },
  {
    id: "07431ec5-7ad3-415c-83fc-19f11ee17e14",
    nome: "MUSEEUW",
  },
  {
    id: "0627a8cb-205a-4003-9fa2-1e3fa7e91af3",
    nome: "MUZZICYCLES",
  },
  {
    id: "9c2f8399-21c4-4994-bdae-b71df006022f",
    nome: "NATHOR",
  },
  {
    id: "a38ddcc3-f386-4a44-b706-063f65bbd442",
    nome: "NEILPRYDE",
  },
  {
    id: "6e77ecda-eded-4894-bb1d-6913c6dd9502",
    nome: "NEW BIKE",
  },
  {
    id: "a2509d62-932c-46e2-ab01-5ba806b3f04d",
    nome: "NEW RUN RIDE",
  },
  {
    id: "b6568442-a42e-4329-9d72-7841ac52662c",
    nome: "NIKONNA",
  },
  {
    id: "82e33d1d-3608-4e7d-a212-113a248c5afa",
    nome: "NINER",
  },
  {
    id: "347776d4-caac-4535-9842-45b1143bac5a",
    nome: "NIRVE",
  },
  {
    id: "0f5e44b9-36ce-4640-a82a-8ea2f3bbd6b2",
    nome: "NISHIKI",
  },
  {
    id: "92835685-55ae-4d19-9b68-97791d3f0770",
    nome: "NORCO",
  },
  {
    id: "7234f86c-f4da-471d-8703-80076c6e1514",
    nome: "NOSTER",
  },
  {
    id: "a01b2ccd-f4f2-45fe-97cb-094cff402c2b",
    nome: "NOVA MARCA",
  },
  {
    id: "204ab142-2bb0-4c90-88b1-271b8dc85780",
    nome: "NOVARA",
  },
  {
    id: "472647be-c141-4e61-a824-ce9539210561",
    nome: "NOVELLO",
  },
  {
    id: "5593424a-8f36-4151-8a3f-b98728a4ef4c",
    nome: "NOVO TESTE",
  },
  {
    id: "dfc92a1e-3ba1-4374-95b7-8b3159c1d15c",
    nome: "NS BIKES",
  },
  {
    id: "ec7448f8-f807-4c48-9ad6-b8554050c125",
    nome: "NUKEPOCTANE ONEROOF",
  },
  {
    id: "e68c9f57-fba8-49a8-a191-065ade29a6e0",
    nome: "NUKEPROOF",
  },
  {
    id: "a549c359-8837-4548-acd0-39d453bcc72b",
    nome: "NUVEM",
  },
  {
    id: "47356e0c-0380-4b63-932e-6bf8cdabf3d6",
    nome: "OCEANO",
  },
  {
    id: "6c06ceed-d911-4766-91e5-43221884a2a6",
    nome: "OCP",
  },
  {
    id: "42526c94-6604-4822-b84a-531e5dc307c6",
    nome: "OGGI",
  },
  {
    id: "ecd16223-24b5-4e3b-b1d3-881812e0e63a",
    nome: "OLYMPIA",
  },
  {
    id: "6eb2c0d4-9f09-4ac1-af07-5632f5e4c0ad",
    nome: "ON ONE",
  },
  {
    id: "cbbe1082-55e1-4f22-861f-9ff0789b9d92",
    nome: "ONE",
  },
  {
    id: "dc4a8591-179e-4273-90ea-9171c29ceb65",
    nome: "OPEN",
  },
  {
    id: "0334ea8a-5c2a-41c8-868c-266e56b14166",
    nome: "OPTIMA",
  },
  {
    id: "d944b061-5742-462e-a2e2-6caefacd0ad9",
    nome: "ORBEA",
  },
  {
    id: "138a5d93-546d-40fb-b65a-e99f34114ce0",
    nome: "ORIGIN8",
  },
  {
    id: "9c14fa64-073d-42be-98d6-69366085f9ca",
    nome: "ORTLER",
  },
  {
    id: "7e875bc0-45f2-4127-ac61-7cf56d06ed1d",
    nome: "OX",
  },
  {
    id: "dee8c5d1-fe5b-4766-8312-ac5d5a74fa66",
    nome: "OX PRO",
  },
  {
    id: "68bbb001-a9f4-4f35-af81-1d9939f89a00",
    nome: "OXER",
  },
  {
    id: "62e4228a-522a-461f-875c-7a564cfdb891",
    nome: "OZARK TRAIL",
  },
  {
    id: "97460f2b-1159-4687-91d4-0459abc8aa17",
    nome: "PALAZZO",
  },
  {
    id: "a8b127b8-c438-40c7-8aa2-7aa0840973d4",
    nome: "PASHLEY",
  },
  {
    id: "b3709eee-be0b-44e1-9c48-52b965f9e70d",
    nome: "PEGASUS",
  },
  {
    id: "e38ffbd8-9abf-4def-8519-e6352cd1d5d9",
    nome: "PEONY",
  },
  {
    id: "da5f4672-e0de-4a95-8c13-656de3d07975",
    nome: "PEUGEOT",
  },
  {
    id: "eff4547b-0e41-4831-beef-2795921c313f",
    nome: "PHILLIPS",
  },
  {
    id: "dea86e02-607d-4c69-ba6c-2110d7d45ee5",
    nome: "PINARELLO",
  },
  {
    id: "625e73bf-1ef3-4a75-a0c6-540c4344779b",
    nome: "PINNACLE",
  },
  {
    id: "7d0b3b3e-dffa-41f7-a3a7-887ead46fae2",
    nome: "PIVOT",
  },
  {
    id: "9bf6bfa5-190d-4a7e-be72-830757921b87",
    nome: "PLANET X",
  },
  {
    id: "49b4b393-5149-4b5a-a598-4e9b46af0c0c",
    nome: "PLIAGE",
  },
  {
    id: "08a2fcd6-2f8e-450b-8538-44b2bf22e4e2",
    nome: "POLIMET",
  },
  {
    id: "df92c0bd-26de-4c18-a8ea-63a7a099b97b",
    nome: "PORTO SEGURO",
  },
  {
    id: "10426139-da02-42ba-9b1f-6bf7f19a6c0b",
    nome: "PREY",
  },
  {
    id: "9e289ad3-2ed5-4897-889e-c4bfdb247f61",
    nome: "PRINCE",
  },
  {
    id: "095f3621-e9f4-4e0b-bb72-cccc7d55c68c",
    nome: "PRIORITY",
  },
  {
    id: "ef132b37-5de4-4588-94bd-954c8eb9d079",
    nome: "PRIVITERA",
  },
  {
    id: "a1e733d9-126a-4ef5-89b0-5aeba3039701",
    nome: "PRO LITE",
  },
  {
    id: "d182f65b-1ba7-4718-a988-c4dca7ea398d",
    nome: "PRO-X",
  },
  {
    id: "5228aadc-8fd4-4cea-a9c9-761be6835bd2",
    nome: "PROFLEX",
  },
  {
    id: "6e8793c3-162c-4c4d-ae30-5b9c163f012f",
    nome: "PROSHOCK",
  },
  {
    id: "cae081b5-3ae1-4c08-ada1-86bd5cdd1438",
    nome: "PUBLIC",
  },
  {
    id: "6a97c5ac-62cd-4343-8898-6f4fe9cce944",
    nome: "PUMMA",
  },
  {
    id: "a4a0b797-92f2-422d-9ef4-9eb11fc32ae0",
    nome: "PURE",
  },
  {
    id: "d018478f-9d5c-46ab-93f7-d5f3157bfe55",
    nome: "PZZ RACING",
  },
  {
    id: "94e0df8f-86d8-4fd0-b47c-6c228fe79669",
    nome: "QUINTANA ROO",
  },
  {
    id: "a01a2de6-e640-4fda-a7c0-5178ddb52b0d",
    nome: "R-WOOD",
  },
  {
    id: "7ef1ab05-98dd-4dee-81ac-dc71614ef38e",
    nome: "RACCER",
  },
  {
    id: "52249cd5-3fbf-4537-859f-389905c2811c",
    nome: "RADON ",
  },
  {
    id: "ce5d0413-ced7-4c75-bc32-57ac597c873f",
    nome: "RAF",
  },
  {
    id: "b5fc8fc5-92bf-4c18-a121-ff534de7ed1e",
    nome: "RAINBOW",
  },
  {
    id: "c6fb7d6c-0200-4d76-be1b-2914d46fcf5a",
    nome: "RALEIGH",
  },
  {
    id: "7ac40d06-9209-437e-acae-a50154498ccb",
    nome: "RALLY",
  },
  {
    id: "f73480e9-4def-4bfb-91ff-c72a10f2c258",
    nome: "RAPTOR",
  },
  {
    id: "cab133d5-65f3-45e2-ae4c-2869b2c8f300",
    nome: "RC BIKES",
  },
  {
    id: "b4d2bc38-6d34-4bc1-ac7a-1d97b5dc809b",
    nome: "REDFOOT",
  },
  {
    id: "876acbd8-f5ce-4d2f-968f-668beccee5c8",
    nome: "REDLAND",
  },
  {
    id: "01f1b899-df18-4c6e-9130-338436d67d21",
    nome: "REDLINE",
  },
  {
    id: "d30dd7cc-c766-4309-8f4a-aa02a0a2de8a",
    nome: "REDSTONE",
  },
  {
    id: "23a2d679-fe53-476d-a40c-45844417ea8a",
    nome: "REEBOK",
  },
  {
    id: "c4823dd7-8d00-4f3e-8bff-285d79d82713",
    nome: "REID",
  },
  {
    id: "81814e7d-cf8e-4d29-92aa-81f5b6e90e8c",
    nome: "RENAULT",
  },
  {
    id: "49f01a91-dd0a-411e-9936-5bc8f3c7ba65",
    nome: "REPÚBLICA DA BICICLETA",
  },
  {
    id: "e230e0b1-b0c0-448f-9d0a-04bb573d4e47",
    nome: "RETROSPEC",
  },
  {
    id: "b977fd73-2efe-443f-8691-4da6894f8c52",
    nome: "REX",
  },
  {
    id: "f07706ef-67b8-400a-9d66-e874a847532a",
    nome: "RHARU",
  },
  {
    id: "cf4eb1f1-680b-4779-88d5-ef5b143cf852",
    nome: "RIDGEBACK",
  },
  {
    id: "9509859d-d617-4fc0-ae46-31179f8abbef",
    nome: "RIDLEY",
  },
  {
    id: "b206251a-ffdc-45b9-b2aa-bf9f2ef8fd80",
    nome: "RIESE&MÜLLER",
  },
  {
    id: "a39b0936-c442-44b5-bd80-f75219a494eb",
    nome: "RIOSOUTH",
  },
  {
    id: "2b11d610-f553-4798-bbfa-3b3c0a98656a",
    nome: "RITCHEY",
  },
  {
    id: "3baa9a71-6aaf-4949-8d8a-7287e2038e3e",
    nome: "RIVER",
  },
  {
    id: "9343fcf4-5521-4af4-b1c9-4ca1e02db654",
    nome: "ROCKRIDER",
  },
  {
    id: "778605c0-5942-449c-a15f-9caa024c4a1c",
    nome: "ROCKWAY",
  },
  {
    id: "1e708350-271f-4f0e-9043-b4f38e9cf51b",
    nome: "ROCKY MOUNTAIN",
  },
  {
    id: "e24454ce-f4de-4deb-9c88-a4cb7ab39be8",
    nome: "ROCOMO",
  },
  {
    id: "555b8f43-ded6-4317-a8bb-4363f235e42c",
    nome: "RONGHUI",
  },
  {
    id: "82fb9de9-d2bb-4443-a360-1d6878d7abb2",
    nome: "ROOSTER",
  },
  {
    id: "c7ae02bf-f6b1-4bf0-b7c8-b23c91aa69c8",
    nome: "ROSA",
  },
  {
    id: "cb6c271a-bdb2-4327-bc0e-ac0a386c3dbf",
    nome: "ROSSETTI",
  },
  {
    id: "516f3664-c0af-425e-9611-1fe318ecf5c5",
    nome: "ROTA HORIZONTE",
  },
  {
    id: "5cb961a3-f24e-4d3f-9c71-c649915a51a4",
    nome: "ROYALBABY",
  },
  {
    id: "e34757e1-97fd-493e-b3dd-8436a5863927",
    nome: "ROYCE UNION",
  },
  {
    id: "9bbff85e-94d8-410a-a3a4-76cda30bdffe",
    nome: "RSC",
  },
  {
    id: "b3adc22b-469f-466e-bb4b-393850d67955",
    nome: "RUPTION",
  },
  {
    id: "0096988a-239e-430a-b1c3-c8bb9276cebd",
    nome: "RUSH",
  },
  {
    id: "9d0ac2af-292e-48e1-a1a3-5a2660b6275e",
    nome: "S-WORKS",
  },
  {
    id: "2bc32737-4a67-4c44-b14b-af9fdf3f7f8f",
    nome: "Safire",
  },
  {
    id: "f20d633b-383e-44d4-9f2c-2730afdd7b74",
    nome: "SAIDX",
  },
  {
    id: "5e6cb722-19dc-48b1-bdee-3b58e63c8d2b",
    nome: "SALSA",
  },
  {
    id: "f8f13116-45cb-4fca-b43a-e903ed48a9f0",
    nome: "SAMPSON",
  },
  {
    id: "08c151ed-45a1-479e-9854-ce7b192ff136",
    nome: "SAMY",
  },
  {
    id: "19d78cbf-279a-4b7d-acd6-6f846b16e836",
    nome: "SANMARCO",
  },
  {
    id: "498b8e57-39d0-46e2-b556-19e398dfb817",
    nome: "SANTA CRUZ",
  },
  {
    id: "6e0f139d-a31e-4a20-b03d-ddfadf3a26e1",
    nome: "SANTA MONICA",
  },
  {
    id: "2780c434-37eb-486a-8aa9-8733748dbaaa",
    nome: "SANTINNI",
  },
  {
    id: "23821d95-3911-4777-b2dd-9fa5f5f5b898",
    nome: "SANTOS",
  },
  {
    id: "569f7456-41c4-4cf7-89a7-bd96a8fe7f4a",
    nome: "SAVA",
  },
  {
    id: "7543c36d-ec17-44c1-a934-56413053def5",
    nome: "SAVOY",
  },
  {
    id: "dc97c613-a9a7-4c7a-880a-103f3cdec0ca",
    nome: "SCATTANTE",
  },
  {
    id: "1e0a0a92-4b4e-4ff8-bde3-4675d4ac96f7",
    nome: "SCHWINN",
  },
  {
    id: "a5a09b60-329a-4dbf-ae45-bd0c73d9d81c",
    nome: "SCIROCCO",
  },
  {
    id: "48afc1ba-73cd-49da-9d75-cf6bd809830d",
    nome: "SCOOTER BRASIL",
  },
  {
    id: "0cb4dc7d-f8cc-421a-be18-ab6adbf5f51c",
    nome: "SCOTT",
  },
  {
    id: "f3ae704a-1940-4759-a8c3-097f51035489",
    nome: "SCREPPER",
  },
  {
    id: "f5fa39f1-4819-423a-9fcf-5a68508aa832",
    nome: "SCUD",
  },
  {
    id: "e76e7ab7-ecd7-4ab2-a827-8811ded0d394",
    nome: "SENSE",
  },
  {
    id: "0011cc1d-367f-4130-84cf-407c6706b42e",
    nome: "SERFAS",
  },
  {
    id: "bd702c81-b7bf-4eab-abb9-aa4ad2cae73c",
    nome: "SEROTTA",
  },
  {
    id: "9d80aace-5f89-4247-bfef-17a1ce392511",
    nome: "SETTE",
  },
  {
    id: "774e8e5f-af18-4a48-bec8-996a073d46a8",
    nome: "SEVEN CYCLES",
  },
  {
    id: "0e52e4f7-cb4b-4104-a5be-5fcfee5b0b56",
    nome: "SHIMANO",
  },
  {
    id: "461a16e6-bb2f-4ab3-9870-cb213550294f",
    nome: "SHINERAY",
  },
  {
    id: "8567850e-efae-4c37-9db2-d8f5ed1a133f",
    nome: "SHOCKBLAZE",
  },
  {
    id: "87d3ecd9-006b-4b24-9de9-b1bdd3f5a0d0",
    nome: "SINTESI",
  },
  {
    id: "dd62b06c-e3a0-4365-9c07-ee4ef44bbb93",
    nome: "SIXXIS",
  },
  {
    id: "4703ed5f-90d7-47c1-af97-b3f3434f554b",
    nome: "SKY",
  },
  {
    id: "fde59e9b-cdae-48d5-9c2b-52bba5e52449",
    nome: "SKY LINE",
  },
  {
    id: "ba1ea23c-3a95-4945-b072-9273269e825a",
    nome: "SLINGSHOT",
  },
  {
    id: "9bb5ddcb-2709-49de-8971-978d4f7cbde4",
    nome: "SLP",
  },
  {
    id: "fc52ed15-2aa4-46e5-bd7a-1b417418b56b",
    nome: "SMART",
  },
  {
    id: "e4bf4ece-2b06-484a-a9cc-4990aea91d7b",
    nome: "SMX",
  },
  {
    id: "2c947299-102f-4b5c-bd20-ddd9c7626886",
    nome: "SNOW",
  },
  {
    id: "ae2dfe2d-1f5d-4d00-a2d4-e6d079817029",
    nome: "SOBATO",
  },
  {
    id: "93ffd8a5-4ba5-4aa7-b84a-f1ffc3778752",
    nome: "SOFTRIDE",
  },
  {
    id: "58c0bc12-01b3-4487-87d9-8f14296e6888",
    nome: "SOLYOM",
  },
  {
    id: "e56a6340-2bde-467e-8af3-9795b75ccc47",
    nome: "SOMAKRE",
  },
  {
    id: "07445d1d-be24-4eaf-a93b-f1257db86082",
    nome: "SOUL",
  },
  {
    id: "d917253a-ac9d-4fc6-9824-54f0a3f622e6",
    nome: "SOUTH",
  },
  {
    id: "c3bb1379-38bb-405b-b99c-1f8072ccb2d0",
    nome: "SPACE",
  },
  {
    id: "93cb9172-28b8-4742-ba7d-6301d1bc740e",
    nome: "SPECIAL LIFE",
  },
  {
    id: "340fd971-bc25-4952-acb5-cf80355ddd9c",
    nome: "SPECIALIZED",
  },
  {
    id: "cd77be0b-b3e2-4704-962b-e71eabb817fd",
    nome: "SPEEDO",
  },
  {
    id: "55b7e379-b302-4389-9750-1c3f81d2123c",
    nome: "SPIN",
  },
  {
    id: "42499515-262a-47e3-9ae4-7d4830188d31",
    nome: "SPINO",
  },
  {
    id: "4c662cd4-ce52-4188-82fe-70c1d84593c7",
    nome: "SPORTIX",
  },
  {
    id: "d1c2638c-6e19-4de3-b5d2-15c42473e38c",
    nome: "SPRICK CYCLE",
  },
  {
    id: "1689797a-5a91-491f-ade7-5d7a6cc59a61",
    nome: "STAMINA 29",
  },
  {
    id: "00c31e33-284e-4405-88a7-86a95a0a3d58",
    nome: "STATE",
  },
  {
    id: "72f106e6-a4be-4d1d-a3a4-4ecf78292986",
    nome: "STATUS",
  },
  {
    id: "27d6a3df-6f8e-4952-89f6-f60cad8fb163",
    nome: "STEREO BIKES",
  },
  {
    id: "ea7c36a2-0dc3-47f3-81ec-0c99444ed582",
    nome: "STEVENS",
  },
  {
    id: "8802b52e-1b5d-4a56-84d8-2bc56b298e8d",
    nome: "STINGRAY",
  },
  {
    id: "425fb52b-0dc0-4db4-9a4b-eab47db48c4c",
    nome: "STL",
  },
  {
    id: "35b2eef8-8947-465e-a6e3-66cf3beed56c",
    nome: "STOLEN",
  },
  {
    id: "8b757ed1-e441-44ac-a9f6-b1030e4fa48e",
    nome: "STONE",
  },
  {
    id: "b6325f0f-34cc-4b9b-8088-d9af4e16c89b",
    nome: "STORCK",
  },
  {
    id: "416849e7-325e-404b-9b75-d3bea2da1ade",
    nome: "STRADALLI",
  },
  {
    id: "f8a5902f-1d45-46b7-86a6-6d1d3efca712",
    nome: "STRADERA",
  },
  {
    id: "f327d395-7836-46b3-90bf-f2b58fa20eb1",
    nome: "STRIDA",
  },
  {
    id: "aed1fa1b-d506-441b-9de5-64a866ec0efc",
    nome: "SUMMER",
  },
  {
    id: "07934d0c-0da5-4885-a925-fcdbb0c5811d",
    nome: "SUNBURST",
  },
  {
    id: "f33dccd8-72b5-4e34-aa6e-cc24a69de8f2",
    nome: "SUNDOWN",
  },
  {
    id: "7d66d2f7-a832-454f-a20b-c4af3a8e7b1e",
    nome: "SUNN",
  },
  {
    id: "a11fdd66-799c-4590-b1d7-e6b25dcd5ac1",
    nome: "SUNSET",
  },
  {
    id: "3aeda800-53f2-4370-bb00-6593d3c3ec10",
    nome: "SUNTOUR",
  },
  {
    id: "b0597849-8ccd-4eaf-b9fe-b1a4c1746fa1",
    nome: "SUPERO",
  },
  {
    id: "7aba61e4-23bc-4c8f-b924-f174a9c3fb48",
    nome: "SURLY",
  },
  {
    id: "38606ab5-796f-4072-8dfd-cd85db90710c",
    nome: "SWIFT",
  },
  {
    id: "afd7fa35-aa39-4a29-aca9-41ffb17ed3ae",
    nome: "T-REX",
  },
  {
    id: "5f3e3909-4a11-4971-8cb1-eb13556948dc",
    nome: "TAEQ",
  },
  {
    id: "297d573d-4304-4f85-8996-f5f9a673b836",
    nome: "TARGET",
  },
  {
    id: "70aab697-31ea-4354-8841-2f2f068cefc3",
    nome: "TERN",
  },
  {
    id: "2b9af0c4-ee32-478e-9404-00498cb94798",
    nome: "TEXAS RANGERS",
  },
  {
    id: "eae4a3b0-cab9-46e1-b93d-bfe44fca9c92",
    nome: "TI CYCLES",
  },
  {
    id: "4c2a76ce-eb8c-47b0-8205-a5880a99199c",
    nome: "TIME",
  },
  {
    id: "45aa9787-2d74-4ac8-b687-4be8e262d588",
    nome: "TITO",
  },
  {
    id: "f60f0423-07fb-4988-a690-50dba417217c",
    nome: "TITUS",
  },
  {
    id: "34bc137f-39f5-4000-9741-6c907014f3f7",
    nome: "TOKEN",
  },
  {
    id: "9e3d502a-0cab-42ba-99c1-964d9314bccd",
    nome: "TOPBIKE",
  },
  {
    id: "237425e8-8c63-4857-bfab-5aa83e9a489c",
    nome: "TORELLI",
  },
  {
    id: "c899355d-65d3-4690-957d-a2581da69145",
    nome: "TORONTO",
  },
  {
    id: "8a9fa6b3-bb94-4b06-943b-cda21b2159c4",
    nome: "TOTEM",
  },
  {
    id: "b9d92e9a-6e0f-46b8-93ab-f740929ad720",
    nome: "TOUR DE FRANCE",
  },
  {
    id: "71d637ff-0b9c-4470-8dc3-8e125b6d8353",
    nome: "TRACK & BIKES",
  },
  {
    id: "ce649441-9fd5-498d-9c5a-0266707fe253",
    nome: "TRACTOR",
  },
  {
    id: "45089a37-a5eb-47bd-a311-b73a7a5fe421",
    nome: "TRANSITION",
  },
  {
    id: "787f00a9-59db-4066-ad1c-7b1e2c49821b",
    nome: "TRE3E",
  },
  {
    id: "d08bfa35-9deb-443b-bfcb-9f7d1a422fab",
    nome: "TREK",
  },
  {
    id: "3ece0143-a97f-4e9f-9564-74a2e21bfb53",
    nome: "TRIAX",
  },
  {
    id: "d59f15ba-27bd-40c1-aa15-dc3e1068fc13",
    nome: "TRIGON",
  },
  {
    id: "33e179c3-0cd0-4b83-9c89-edc7fa2a3ab4",
    nome: "TRINX",
  },
  {
    id: "604d5ecb-d37e-4bec-a8de-d0db7e835a4a",
    nome: "TRIUMPH",
  },
  {
    id: "2263e060-8481-48b3-afa6-1afd0f1bc5ce",
    nome: "TROTZ",
  },
  {
    id: "ad53fc92-0498-44cc-bf18-c2793f231288",
    nome: "TRUST",
  },
  {
    id: "1fdcbbee-54b8-4488-94e2-add490ad1ebc",
    nome: "TRYTO",
  },
  {
    id: "c4dd31f9-4c6f-4489-aa3e-d9b453ca8162",
    nome: "TSW",
  },
  {
    id: "e25ef372-5331-49a9-829d-095272c11c5a",
    nome: "TURNER",
  },
  {
    id: "c3b7fcdb-51d8-4a14-b59d-06fe8c6e301c",
    nome: "TWIN SIX",
  },
  {
    id: "5c0e98bb-7ac3-4445-8dd0-3991a8a9e70a",
    nome: "TWO DOGS",
  },
  {
    id: "9c469fe7-fe61-466a-a9c8-fc0a119d89d3",
    nome: "TWO HARD",
  },
  {
    id: "b200b8b8-789b-474d-81ea-cf68c584f2e1",
    nome: "ULTIMATE",
  },
  {
    id: "659bcee4-e864-42d3-af26-993b1ec4feb3",
    nome: "UMF",
  },
  {
    id: "1ba66e03-9860-44d0-8733-bf2bde61fdac",
    nome: "UNITED PELOTON",
  },
  {
    id: "636c374a-a83a-4e86-b586-1e407f978a29",
    nome: "UNIVEGA",
  },
  {
    id: "9dc0cc68-95df-42ce-ae78-c3517c154032",
    nome: "UPLAND",
  },
  {
    id: "56a1c09b-ad6c-4003-9ebd-ee2aeb5ca09f",
    nome: "URBANA",
  },
  {
    id: "f8fa9a96-41c4-4b52-a26d-e646a1590731",
    nome: "VAN DESSEL",
  },
  {
    id: "e9622f3a-8cb1-4957-8e22-e93a497db100",
    nome: "VAXES",
  },
  {
    id: "c951788f-471e-4193-ae0b-41cf2379c0ff",
    nome: "VEHEN",
  },
  {
    id: "5b9de9e1-8796-4692-a31d-41654909b112",
    nome: "VELA",
  },
  {
    id: "9165df42-49cd-4223-8c2a-68bcc2733427",
    nome: "VELLE",
  },
  {
    id: "96a7e81e-e19c-42fb-9fc7-47c9983c1051",
    nome: "VELOBUILD",
  },
  {
    id: "93b717ff-bdd2-4ade-a960-9d8cfbc552c1",
    nome: "VELOFORCE",
  },
  {
    id: "d570265c-0bbf-4200-8a85-1d290453ee67",
    nome: "VENZO",
  },
  {
    id: "987b9900-405f-4f3a-a9ed-199324f7ab34",
    nome: "VERCELLI",
  },
  {
    id: "39d17e06-5e33-4ff3-9fbd-e00de780242a",
    nome: "VERDEN",
  },
  {
    id: "19932fe7-58e7-4afc-95b6-a21569e6e24a",
    nome: "VFS CUSTOMS",
  },
  {
    id: "f1dc07ab-4133-4f75-b59a-043babcfadd9",
    nome: "VICINI",
  },
  {
    id: "c29a5f02-b0df-4d0b-a1bb-bead5a1eef96",
    nome: "VICINITECH",
  },
  {
    id: "7a188869-84fe-4d8e-85f9-226a47df8c2f",
    nome: "VIKINGX",
  },
  {
    id: "2ae867fa-e6ac-407a-a72a-eb06e4585ff2",
    nome: "VINER",
  },
  {
    id: "fce2da08-d2ff-47f9-9614-269933102a0b",
    nome: "VIRTUE",
  },
  {
    id: "8b1d8de1-d6d6-406e-b4f9-233744e1fe0a",
    nome: "VISION",
  },
  {
    id: "e666e9d9-7498-4f1d-88c4-2af531f91866",
    nome: "VITUS",
  },
  {
    id: "403a93d2-1168-4acf-8ced-4b1aae1f9d34",
    nome: "VIVATEC",
  },
  {
    id: "431ed8f3-2f93-4f6b-af6c-bcec17d43f7f",
    nome: "VO2",
  },
  {
    id: "7de6ad8b-e3b0-496c-b193-a0de560278db",
    nome: "VO2BIKE",
  },
  {
    id: "40faf0d0-e18d-40f9-96b6-70692433d1de",
    nome: "VOLARE",
  },
  {
    id: "c7fb6a6b-d3a0-469f-8a84-aa04fd0b9032",
    nome: "VOLTEC",
  },
  {
    id: "88026fda-014b-4e2f-8c19-a1d26a05e0af",
    nome: "VOLUME",
  },
  {
    id: "b99daa55-dfef-43b6-a80d-915b49b05f02",
    nome: "VOOX",
  },
  {
    id: "8b86d909-a980-4e34-a5d5-30e2a29c20fb",
    nome: "VOYCE",
  },
  {
    id: "ea8d49ae-b70f-4189-af1e-5a7901607d5e",
    nome: "VZAN",
  },
  {
    id: "fce16de8-267d-4f5f-a290-2333447da7cf",
    nome: "WATERFORD",
  },
  {
    id: "985ac4bc-a67f-4f13-b99a-11264e222f71",
    nome: "WATTS MOTORS",
  },
  {
    id: "4890cb3b-7100-4d05-8846-4fe656634350",
    nome: "WBT",
  },
  {
    id: "a216e349-6063-4a94-91c9-94d3410f7f54",
    nome: "WENDY",
  },
  {
    id: "32700f3d-c0cf-420f-b3dd-780fd333507d",
    nome: "WENDYBIKE",
  },
  {
    id: "4095b3bd-d519-41f1-9268-6cb5be627fc7",
    nome: "WHEELER",
  },
  {
    id: "e166d6d5-614f-4562-8ad3-30ccbd47d768",
    nome: "WILIER",
  },
  {
    id: "89d0da26-7b2b-445f-b456-773d4f18e9e5",
    nome: "WINDSOR",
  },
  {
    id: "503c3ed9-62e6-43b2-9da3-e8b1be44ae84",
    nome: "WINNING ",
  },
  {
    id: "8d6b939b-7227-48ff-8cb7-ac592acb3685",
    nome: "WNY",
  },
  {
    id: "2f61cf5d-c121-4a44-809a-5840fd125117",
    nome: "WOIE",
  },
  {
    id: "dc1a3640-63b9-4e6d-bacb-6e337dd54118",
    nome: "WRP",
  },
  {
    id: "81a21166-7141-4c2d-9122-14f94c918732",
    nome: "WS CRUISER",
  },
  {
    id: "5143c8d1-cc8d-41f8-a52b-3c9e4617802b",
    nome: "X RACING",
  },
  {
    id: "eb655159-abfc-418e-9f4b-51ff78624957",
    nome: "X TERRA",
  },
  {
    id: "f9a40e39-8a0a-4702-965c-dd04cedaee94",
    nome: "X-PLORE",
  },
  {
    id: "e02be914-8e42-4b6c-bd46-187c41b9b2c1",
    nome: "XKS",
  },
  {
    id: "043a08ba-e207-4f37-b35b-3d970a262a27",
    nome: "XTGS",
  },
  {
    id: "a604032f-ed35-40a0-9975-3e307f54f050",
    nome: "YEDOO",
  },
  {
    id: "91cf1319-19c7-4df4-8301-baec2489bb53",
    nome: "YETI",
  },
  {
    id: "72287e38-f805-4f2e-9b4d-1e9983bf703d",
    nome: "YOELEO",
  },
  {
    id: "5d1613f4-fccd-4fb0-9ff0-03fc3005cbb9",
    nome: "YOSEMITE",
  },
  {
    id: "5b927dc5-aa19-4d1d-904c-fdaad9912036",
    nome: "YT INDUSTRIES",
  },
  {
    id: "35507b5d-5164-49c3-a36f-a3e2aa1fbb21",
    nome: "ZENITH",
  },
  {
    id: "9d7f0f64-da94-443b-b7b4-ba7ce3a37121",
    nome: "ZIPP",
  },
  {
    id: "8e416812-04b2-49c0-801d-59e6aa7d9eb9",
    nome: "ZOHRER",
  },
  {
    id: "da249463-ccf6-4651-bde2-c1c899b326e5",
    nome: "ZUMMI",
  },
  {
    id: "07eff73f-7d09-4ba5-b689-6e90646eff6f",
    nome: "ZYMMER",
  },
];

export const ACESSORIES = [
  {
    id: "0c0b25c7-4c40-461a-b612-e4f292586542",
    nome: "Ciclocomputador / GPS / Garmin",
  },
];
